<template>
  <div class="custom-content-height pb-8 poppins">
    <div :class="['container-fluid', { 'pl-15': $vuetify.rtl }]">
      <div
        :class="[
          'row justify-content-between',
          { 'pl-xl-3 pl-xxl-4': $vuetify.rtl },
        ]"
      >
        <div class="col-12 col-md-auto ml-lg-4 mb-6">
          <h3 class="font-weight-bolder mb-1">{{ $t("returns") }}</h3>
          <p class="text-dark-50 font-weight-bold">
            {{ $t("returns") }} -{{ $t("dashboard") }}
          </p>
        </div>
        <div class="row justify-content-end">
          <div class="col-12 col-md-auto pt-0 pt-md-4 d-lg-flex flex-wrap">
            <div class="ml-2 ml-md-0 pr-2 pr-md-0">
              <FilterSelector
                style="margin-top: 6px"
                :filters="filters"
                :update-data-of-charts="updateDashboardData"
              ></FilterSelector>
            </div>
          </div>
          <div
            :class="[
              'col-12 col-md-auto pt-0 pt-md-4 d-lg-flex flex-wrap px-0',
              { 'mr-5 mr-xl-16': !$vuetify.rtl },
            ]"
          >
            <div class="ml-9 ml-md-0 pr-2 pr-md-0 w-lg-200px">
              <!--  -->
              <div style="margin-top: 10px">
                <!--begin::Datepicker-->
                <date-range-picker
                  class="w-100 w-md-auto"
                  ref="picker"
                  :opens="datePickerPosition"
                  :locale-data="{ firstDay: 1, format: 'dd/mm/yyyy' }"
                  :close-on-esc="false"
                  :showDropdowns="true"
                  min-date="01-01-2018"
                  :max-date="new Date().toLocaleDateString()"
                  control-container-class="form-control max-h-45px"
                  v-model="dateRange"
                  :ranges="ranges"
                >
                  <!--            @select="selectDate"-->
                  <template v-slot:input="picker">
                    <div class="h-100 d-flex align-center">
                      <span class="svg-icon svg-icon-2 mr-2">
                        <v-icon size="22">mdi-calendar</v-icon>
                      </span>

                      <input
                        type="text"
                        class="custom-input"
                        placeholder="Select a date"
                        :value="
                          picker.rangeText === ' - ' ? '' : picker.rangeText
                        "
                      />
                    </div>
                  </template>

                  <template v-slot:footer="picker">
                    <div class="drp-buttons py-4 px-6 poppins">
                      <button
                        type="button"
                        class="btn btn-light ls1 py-2 px-7"
                        @click="picker.clickCancel"
                      >
                        Cancel</button
                      ><button
                        @click="picker.clickApply"
                        type="button"
                        class="btn btn--export-filter ls1 py-2 px-4"
                      >
                        Apply
                      </button>
                    </div>
                  </template>
                </date-range-picker>
                <!--end::Datepicker-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="d-flex row px-3 px-xl-6" v-if="showData">
        <div
          v-for="(chart, index) in allCharts"
          :key="index"
          :class="['ma-0 py-0 px-1', chart.class]"
        >
          <dashboard-label v-if="chart.type == 'label'" :item="chart">
          </dashboard-label>

          <fulfillment-orders-counter-chart-extended
            v-if="chart.type == 'counter'"
            :item="chart"
            :index="index"
            :filterData="dataOfFilters()"
            redirect-url="/shipping/return_orders"
          ></fulfillment-orders-counter-chart-extended>
          <fulfillment-per-country-column-chart
            v-if="chart.type == 'column'"
            :item="chart"
            :filterData="dataOfFilters()"
          ></fulfillment-per-country-column-chart>
          <FulfillmentPerformanceDonutChart
            v-if="chart.type == 'donut'"
            :item="chart"
            :filterData="dataOfFilters()"
          ></FulfillmentPerformanceDonutChart>
          <OrdersProgressAreaChart
            class="mt-5"
            v-if="chart.type == 'area'"
            :item="chart"
            :filterData="dataOfFilters()"
          ></OrdersProgressAreaChart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import FulfillmentOrdersCounterChartExtended from "@/own/components/dashboard/FulfillmentOrdersCounterChartExtended.vue";
import FulfillmentPerformanceDonutChart from "@/own/components/dashboard/FulfillmentPerformanceDonutChartNew.vue";
import OrdersProgressAreaChart from "@/own/components/dashboard/OrdersProgressAreaChartNew.vue";
import FulfillmentPerCountryColumnChart from "@/own/components/dashboard/FulfillmentPerCountryColumnChartNew.vue";

import DashboardLabel from "@/own/components/dashboard/DashboardLabelNew.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { UPDATE_DASHBOARD_DATA } from "@/core/services/store/dashboardReturn.module";
import FilterSelector from "@/own/components/dashboard/filters/FilterSelectorNew.vue";

export default {
  name: "ReturnDashboardLayout",
  components: {
    DashboardLabel,
    FulfillmentOrdersCounterChartExtended,
    FulfillmentPerformanceDonutChart,
    OrdersProgressAreaChart,
    FulfillmentPerCountryColumnChart,
    DateRangePicker,
    FilterSelector,
  },
  data() {
    return {
      dialog: false,
      dateTypeTrigger: "today",
      dateType: "today",
      isVisible: true,
      filterData: {},
      dateRange: {
        startDate: this.today(),
        endDate: this.today(),
      },
      intervalId: null,
      refreshInterval: 30000, // refresh interval in ms
      autoRefreshState: false,
    };
  },
  beforeMount() {
    this.loadDataFromServer();
  },
  mounted() {
    this.setAutorefreshState();
    this.startAutoRefresh();
  },
  beforeDestroy() {
    this.stopAutoRefresh();
  },
  methods: {
    loadDataFromServer() {
      this.$store.commit(SET_PAGE_LOADING, true);

      const { startDate, endDate } = this.dateRange;
      const payload = {
        date_range_type: "custom",
        date_range: [startDate, endDate],
      };
      this.$store.dispatch(UPDATE_DASHBOARD_DATA, payload);
    },
    dataOfFilters() {
      let startDate, endDate;
      const pattern = /^\d{1,2}\/\d{1,2}\/\d{2,4}$/;

      if (pattern.test(this.dateRange.startDate)) {
        startDate = this.slashSeperatedToISO(this.dateRange.startDate);
        endDate = this.slashSeperatedToISO(this.dateRange.endDate);
      } else {
        startDate = this.extendedToISO(this.dateRange.startDate);
        endDate = this.extendedToISO(this.dateRange.endDate);
      }
      const filterData = {
        date_range_type: "custom",
        date_range: [startDate, endDate],
        ...this.filterData,
      };
      return filterData;
    },
    slashSeperatedToISO(date) {
      const parts = date.split("/");
      const year = parts[2];
      const month = (parts[0].length == 1 ? "0" : "") + parts[0];
      const day = (parts[1].length == 1 ? "0" : "") + parts[1];
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    },
    extendedToISO(val) {
      const date = new Date(val);
      const formattedDate = date.toISOString().substr(0, 10);
      return formattedDate;
    },
    today() {
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate; // "2023-01-31"
    },
    updateDashboardData(data) {
      this.filterData = { ...this.filterData, ...data };
    },
    startAutoRefresh() {
      if (this.autoRefreshState) {
        this.intervalId = setInterval(() => {
          this.loadDataFromServer();
        }, this.refreshInterval);
      }
    },
    stopAutoRefresh() {
      clearInterval(this.intervalId);
    },
    changeAutorefreshSettings() {
      // store state
      localStorage.setItem("auto_refresh", this.autoRefreshState);
      // operate call
      if (this.autoRefreshState) {
        this.startAutoRefresh();
      } else {
        this.stopAutoRefresh();
      }
    },
    setAutorefreshState() {
      const val = localStorage.getItem("auto_refresh");
      const booleanVal = val === "true";
      this.autoRefreshState = booleanVal;
    },
  },
  computed: {
    showData: function () {
      return this.isVisible;
    },
    isClient: function () {
      return !this.$store.getters.currentUser.data.is_client;
    },
    datePickerPosition: function () {
      if (this.$vuetify.rtl) {
        return "right";
      }
      if (this.$vuetify.breakpoint.mdAndUp) {
        return "left";
      }
      return "";
    },
    allCharts: function () {
      const charts = this.$store.getters.getRETURNDASHBOARDTableData;
      return charts.map((item) => {
        if (item.type === "counter") {
          return { ...item, class: "col-sm-6 col-md-4 col-lg-3 col-xl-3 pb-8" };
        } else if (item.type === "donut") {
          return { ...item, class: "col-12 col-lg-6 pr-7" };
        } else if (item.type === "column") {
          return { ...item, class: "col-12 col-lg-6" };
        } else if (item.type === "area") {
          return { ...item, class: "col-12 pt-4" };
        } else return item;
      });
    },
    filters: function () {
      return this.$store.getters.getRETURNDASHBOARDFilter;
    },
    ranges: function () {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      return {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        "Last 7 Days": [
          new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7),
          new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        ],
        "Last 30 Days": [
          new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30),
          new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        ],
        "This month": [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        ],
        "Last month": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "This year": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        ],
        "Last year": [
          new Date(today.getFullYear() - 1, 0, 1),
          new Date(today.getFullYear() - 1, 12, 31),
        ],
      };
    },
  },
};
</script>
<style>
.custom-input {
  outline: none !important;
  border: none !important;
}
.daterangepicker .drp-calendar td.active {
  background-color: rgba(126, 4, 183, 0.8) !important;
}
.in-range {
  background-color: #f7e6fe !important;
}
</style>

<style lang="scss">
.date-range-selector {
  margin-top: 10px !important;
  .v-input__control {
    .v-input__slot {
      font-size: 12px;
      font-weight: 600;
    }
  }
}
.vue-daterange-picker {
  .form-control {
    border: none;
  }
}
</style>
